import NavigationComponent from '../../component/forms/navigation';
import PropTypes from 'prop-types';
import React from 'react';
import SEO from '../../component/seo';
import { ActionContext } from '../../cra';
import Vigil from "@foxsenseinnovations/vigil-sdk-js";


const IndexPage = ({ location }) => {
  const { trackCurrentPage } = React.useContext(ActionContext);
  React.useEffect(() => {
    trackCurrentPage('PDP Page');
    Vigil.initialize({
      apiKey: process.env.GATSBY_VIGIL_API_KEY ? process.env.GATSBY_VIGIL_API_KEY : "",
      clientVersion: process.env.GATSBY_VIGIL_CLIENT_VERSION ? process.env.GATSBY_VIGIL_CLIENT_VERSION : ""
  });
  }, [])
  
  return (
    <>
      <SEO title='Get Rates Now' />
      <NavigationComponent
        location={location}
        isMedicareAdvantage={false}
        isPDP={true}
        isMS={false}
      />
    </>
  );
};

IndexPage.propTypes = {
  location: PropTypes.object,
};

export default IndexPage;
